/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { AlertManager } from "react-alert";

export const baseURL = process.env.REACT_APP_BASE_URL;

let toggleLoading: (status: boolean) => void;
export const setToggleLoading = (tg: (status: boolean) => void) => {
  toggleLoading = tg;
};

const api = axios.create({
  baseURL,
});

// INTERCEPTORS
api.interceptors.request.use(
  async (config) => {
    toggleLoading(true);
    return config;
  },
  (error) => {
    toggleLoading(false);
    return Promise.reject(error);
  }
);

export const responseInterceptor = (
  signOut: () => void,
  handleOpenLogin: () => void,
  alert: AlertManager
): any => {
  api.interceptors.response.use(
    (response) => {
      toggleLoading(false);
      return response;
    },

    (error) => {
      toggleLoading(false);
      if (error?.response?.config?.url === `/nothing`) {
        throw error;
      }
      if (
        (!error.response || !error.response.data) &&
        (!error.response || !error.response.status)
      ) {
        alert.error(
          "Parece que você está sem internet! \n Verifique sua conexão e tente novamente."
        );

        return error;
      }
      switch (error.response.status) {
        case 400:
          alert.error(error.response.data.message || "Requisição inválida");
          return error;
        case 401:
          alert.error(error.response.data.message || "Não Autorizado");
          handleOpenLogin();
          return error;
        case 404:
          alert.error(error.response.data.message || "Recurso não disponível.");
          return error;
        case 403:
          alert.error("Acesso negado.");
          return error;
        case 500:
          alert.error(
            "Algo inesperado aconteceu, tente novamente em instantes."
          );
          return error;
        default:
          alert.error(
            error.response.data.message ||
              "Algo inesperado aconteceu, tente novamente em instantes."
          );
          return error;
      }
    }
  );
};

export default api;
