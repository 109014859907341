import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Login from "..";
import { useAuth } from "../../../contexts/useAuth";
import * as H from "history";
import { DialogTitle } from "@mui/material";

interface LoginModalProps {
  history: H.History;
}

const LoginModal: React.FC<LoginModalProps> = ({ history }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { openLogin, handleCloseLogin } = useAuth();

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={openLogin}
        onClose={handleCloseLogin}
        aria-labelledby="responsive-dialog-title"
      >
        {fullScreen && (
          <DialogTitle
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            sx={{ mt: 15 }}
          >
            <img src="/images/logo/logo-black.png" alt="" height="64" />
          </DialogTitle>
        )}
        <DialogContent>
          <Login history={history} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default LoginModal;
