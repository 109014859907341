import { Container, Typography, Link, Grid } from "@mui/material";
import React from "react";
import AccountMenu from "../Menu";
function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ py: 2 }}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://www.postoportalpiracaia.com.br">
        www.postoportalpiracaia.com.br
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const LayoutWrapper: React.FC = ({ children }) => {
  return (
    <>
      <AccountMenu />
      <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
        <Grid
          sx={{
            my: 0,
            px: 0,
            minHeight: "85vh",
            backgroundColor: "transparent",
          }}
        >
          {children}
        </Grid>
        <Copyright />
      </Container>
    </>
  );
};

export default LayoutWrapper;
