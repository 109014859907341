import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { MdExpandMore } from "react-icons/md";
import { Box, Chip } from "@mui/material";
import { CouponType } from "../../../../model/coupon";

import { useAuth } from "../../../../contexts/useAuth";
import md5 from "md5";
import { formatCupomNumber, formatDateTime } from "../../../../services/helper";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const checkWinner = (row: CouponType) => {
  if (!row.numerosSorteados)
    return (
      <Chip
        icon={<span style={{ fontSize: 24 }}>⏳</span>}
        label="AGUARDANDO SORTEIO"
        color="default"
      />
    );
  const nums = row.numerosSorteados.split(",");
  return nums.includes(row.numeroCupom.toString()) ? (
    <Chip
      icon={<span style={{ fontSize: 24 }}>🎉</span>}
      label="CONTEMPLADO"
      color="success"
    />
  ) : (
    <Chip
      icon={<span style={{ fontSize: 24 }}>🥹</span>}
      label="NÃO CONTEMPLADO"
      color="error"
    />
  );
};

type CouponProps = {
  coupon: CouponType;
};

const getSecurityCode = (userId: number, numeroCupom: number): string => {
  return md5(`${numeroCupom}:${userId}`);
};

const Coupon: React.FC<CouponProps> = ({ coupon }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { store } = useAuth();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        maxWidth: 380,
        xs: { width: "100%", px: 2 },
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: "#00000000", width: 100 }}
            aria-label="logo"
            variant="rounded"
          >
            <img width={"100%"} src="/images/logo/logo-black.png" alt="ava" />
          </Avatar>
        }
        titleTypographyProps={{ textAlign: "right" }}
        title="Nº do Cupom"
        subheaderTypographyProps={{ textAlign: "right" }}
        subheader={<h2> {formatCupomNumber(coupon.numeroCupom)}</h2>}
        style={{ backgroundColor: "#00000010" }}
      />
      <CardContent sx={{ textAlign: "center" }}>
        <Typography variant="h6" color="text.primary">
          {coupon.nome}
        </Typography>
      </CardContent>
      <CardMedia
        component="img"
        height="194"
        image={coupon.urlImagem || "/images/logo/logo-black.png"}
        alt="image-promo"
      />
      <CardContent sx={{ textAlign: "center" }}>
        <Typography variant="body1" color="text.primary">
          Válido para o Sorteio do dia
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {formatDateTime(coupon.dataHoraSorteio)}
        </Typography>
        {checkWinner(coupon)}

        <Typography variant="overline" color="text.primary">
          {store.user && getSecurityCode(store.user?.id, coupon.numeroCupom)}
        </Typography>
      </CardContent>
      {coupon.tipoSorteio === "globo" && (
        <>
          <CardActions disableSpacing>
            <Typography variant="subtitle1" color="text.primary">
              Minhas Dezenas
            </Typography>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <MdExpandMore />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Box
                display="grid"
                textAlign={"center"}
                gridTemplateColumns="repeat(20, 1fr)"
                gap={2}
              >
                {coupon.dezenaSet.split(",").map((d) => (
                  <Box gridColumn="span 4" key={d}>
                    <div className="bingo-ball">{d}</div>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
};

export default Coupon;
