import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Grid, TextField, Typography } from "@mui/material";
import api from "../../services/api";
import { useAlert } from "react-alert";
import { useAuth } from "../../contexts/useAuth";
import TextFieldMask from "react-text-mask";
import TextMaskCodBar from "../../helpers/masks/TextMaskCodBar";

const validationSchema = yup.object({
  codBar: yup
    .string()
    .required("Favor digitar o nº do Código de Barras do Cupom Fiscal")
    .test("text-cod-bar", "Código de barras inválido", (value) => {
      return value?.trim().length === 44;
    }),
});

const RegisterCouponCodBar: React.FC = () => {
  const alert = useAlert();
  const { store, setStore } = useAuth();

  const refreshUser = async () => {
    const result = await api.get(`/usuario`, {
      headers: { "x-access-token": store.user?.token },
    });
    if (result?.data) {
      setStore({
        ...store,
        user: { ...result.data, token: store?.user?.token },
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      codBar: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (store.user?.token) {
        const result = await api.post("/purchase", values, {
          headers: { "x-access-token": store.user?.token },
        });
        if (result.status === 200) {
          refreshUser();
          alert.success(
            result?.data?.message ||
              "Aguarde o processamento, em breve seus cupons estarão disponíveis",
            {
              onClose: () => {
                resetForm();
              },
            }
          );
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={2}
        sx={{
          px: { xs: 6, md: 10, lg: 20 },
          py: { xs: 6, md: 10, lg: 10 },
          backgroundColor: "white",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h5">
            Cadastrar Cupom Fiscal de Abastecimento
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            InputProps={{
              inputComponent: TextMaskCodBar,
              inputProps: {
                component: TextFieldMask,
              },
            }}
            variant="filled"
            fullWidth
            id="codBar"
            name="codBar"
            label="Nº do código de barras do Cupom Fiscal"
            type="tel"
            value={formik.values.codBar}
            onChange={formik.handleChange}
            error={formik.touched.codBar && Boolean(formik.errors.codBar)}
            helperText={formik.touched.codBar && formik.errors.codBar}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={formik.isSubmitting}
          >
            Validar Cupom Fiscal
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RegisterCouponCodBar;
