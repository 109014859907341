import React, { useCallback, useEffect, useState } from "react";
import { DrawingType } from "../../../../model/drawing";
import { Box, Grid, Typography } from "@mui/material";
import Lottie from "react-lottie";
import animation from "../../../../assets/confetes.json";
import api from "../../../../services/api";
import { WinnerType } from "../../../../model/winner";
import { format, parse } from "date-fns";
// import { Container } from './styles';

interface GanhadorPros {
  drawing: DrawingType;
}

const Ganhador: React.FC<GanhadorPros> = ({ drawing }) => {
  const [winners, setWinners] = useState<WinnerType[]>([]);

  const loadWinners = useCallback(async () => {
    const dataHora = parse(
      drawing.promotion.dataProximoSorteio,
      "yyyy-MM-dd HH:mm:ss",
      new Date()
    );
    const result = await api.post(`/winner`, {
      idPromocao: drawing.promotion.id,
      dataSorteio: format(dataHora, "dd/MM/yyyy HH:mm:ss"),
      page: 1,
      limit: 50,
    });
    if (result?.data) {
      setWinners(result.data.rows);
    }
  }, [drawing.promotion.dataProximoSorteio, drawing.promotion.id]);

  useEffect(() => {
    loadWinners();
  }, [loadWinners]);

  return (
    <>
      {drawing.winners!.map((w, index) => {
        const winner = winners.find((g) => g.numeroCupom === w.numeroCupom);
        return (
          <>
            <Lottie
              options={{
                animationData: animation,
                autoplay: false,
                loop: false,
              }}
              style={{
                position: "fixed",
                width: "100%",
                bottom: 0,
                left: 0,
                margin: 0,
                padding: 0,
                zIndex: 10,
              }}
              width={1920}
              isStopped={drawing.showingWinner !== index}
              isPaused={false}
            />
            <Box
              sx={{
                height: 640,
                backgroundImage: "url('/images/show/bg-cupom.png')",
                backgroundSize: "contain",
                display: drawing.showingWinner === index ? "inherit" : "none",
              }}
              key={w.numeroCupom}
              className="animate__animated animate__flipInX"
            >
              <Grid
                container
                justifyContent={"center"}
                alignContent={"flex-start"}
                paddingY={2}
              >
                <Grid item>
                  <Typography variant="h3">
                    Ganhador {index + 1} de {drawing.winners?.length}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                paddingY={6}
                paddingX={10}
                justifyContent={"space-between"}
                alignContent={"flex-start"}
              >
                <Grid item>
                  <img
                    src="/images/logo/logo-black.png"
                    width={300}
                    alt="logo"
                  />
                </Grid>
                <Grid item textAlign={"right"}>
                  <Typography variant="h6">Nº do Cupom</Typography>
                  <Typography fontWeight={"bold"} variant="h2">
                    {w.numeroCupom}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                paddingX={10}
                justifyContent={"space-between"}
                alignContent={"flex-start"}
                color={"#5e5e5e"}
              >
                <Grid item marginBottom={5}>
                  <Typography variant="h6">Nome do contemplado</Typography>
                  <Typography
                    fontWeight={"bold"}
                    variant="h2"
                    maxWidth={1000}
                    noWrap
                  >
                    {winner?.nome}
                  </Typography>
                </Grid>
                <Grid item textAlign={"right"}>
                  <Typography variant="h6">Telefone</Typography>
                  <Typography fontWeight={"bold"} variant="h2">
                    {winner?.celular}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                paddingX={10}
                justifyContent={"space-between"}
                alignContent={"flex-start"}
                color={"#5e5e5e"}
              >
                <Grid item>
                  <Typography variant="h6">Cidade</Typography>
                  <Typography fontWeight={"bold"} variant="h2">
                    {winner?.cidade} - {winner?.estado}
                  </Typography>
                </Grid>
                <Grid item textAlign={"right"}>
                  <Typography variant="h6">Bairro</Typography>
                  <Typography fontWeight={"bold"} variant="h2">
                    {winner?.bairro}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </>
        );
      })}
    </>
  );
};

export default Ganhador;
