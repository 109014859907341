import React from "react";
import MaskedInput from "react-text-mask";

const TextMaskInteger = React.forwardRef((props: any, ref) => {
  const { component: Component, ...other } = props;
  return (
    <MaskedInput
      {...other}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar=" "
      showMask={false}
    />
  );
});
export default TextMaskInteger;
