import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import md5 from "md5";
import api, { setToggleLoading } from "../services/api";
import { Session } from "../model/session";
import { LocalStorageService } from "../services/localStorageService";

export interface User {
  id: number;
  nome: string;
  cpf?: string;
  email?: string;
  celular?: string;
  dataNascimento: string;
  cidade: string;
  estado: string;
  bairro: string;
  saldoLitros: number;
  token: string;
}
interface SignCredentials {
  login: string;
  password: string;
}

interface AuthContextData {
  toggleLoading: (status: boolean) => void;
  signIn(credentials: SignCredentials): Promise<void>;
  signOut(): void;
  setStore: (session: Session) => void;
  handleOpenLogin: () => void;
  handleCloseLogin: () => void;
  loading: boolean;
  store: Session;
  openLogin: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const initialSession: Session = {
  redirectPath: "/",
  isAuthenticated: false,
  user: undefined,
};

interface AuthProviderProps {
  children: React.ReactNode;
  storage: Session;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children, storage }) => {
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState<Session>(storage);
  const [openLogin, setOpenLogin] = useState(false);

  const handleOpenLogin = () => {
    console.log(Math.random());
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const toggleLoading = (status: boolean) => {
    setLoading((prev) => {
      return prev !== status ? status : prev;
    });
  };

  setToggleLoading(toggleLoading);

  useEffect(() => {
    console.log({ openLogin });
  }, [openLogin]);

  useEffect(() => {
    LocalStorageService.setItem("session", store);
  }, [store]);

  const signOut = useCallback(() => {
    setStore(initialSession);
    LocalStorageService.removeItem("session");
  }, []);

  const signIn = useCallback(async ({ login, password }) => {
    setLoading(true);
    const payload = {
      login,
      password: md5(password),
    };

    const response = await api.post("/auth", payload);
    const {
      token,
      id,
      nome,
      cpf,
      email,
      celular,
      saldoLitros,
      bairro,
      cidade,
      estado,
      dataNascimento,
    } = response.data;

    setStore({
      user: {
        token,
        id,
        nome,
        cpf,
        email,
        celular,
        saldoLitros,
        bairro,
        cidade,
        estado,
        dataNascimento,
      },
      isAuthenticated: true,
      redirectPath: "/",
    });

    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        setStore,
        toggleLoading,
        handleCloseLogin,
        handleOpenLogin,
        loading,
        store,
        openLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
