import { Alert, Snackbar } from "@mui/material";
import { AlertComponentPropsWithStyle } from "react-alert";
import * as React from "react";

const CustomAlertTemplate: React.FC<AlertComponentPropsWithStyle> = ({
  options,
  message,
  close,
}) => {
  return (
    <Snackbar
      key={undefined}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open
      sx={{ mt: 10 }}
      autoHideDuration={options.timeout || 6000}
      onClose={close}
      message={message}
    >
      <Alert onClose={close} severity={options.type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlertTemplate;
