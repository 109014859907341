import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { GroupWinners } from "../../../../model/winner";
import { formatCupomNumber, formatDateTime } from "../../../../services/helper";

interface XsResultsProps {
  winners: GroupWinners;
}

const XsResults: React.FC<XsResultsProps> = ({ winners }) => {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {Object.values(winners).map((group: any, index) => {
        const promoData = group[0]; // Pegue os dados da promoção do primeiro ganhador do grupo
        return (
          <React.Fragment
            key={`${promoData.idPromocao}-${promoData.dataHora}-${index}`}
          >
            {/* Exiba informações da promoção apenas uma vez para cada grupo */}
            <ListItem>
              <ListItemText
                primary={
                  <ListItem sx={{ bgcolor: "#bbbbbb25" }}>
                    <ListItemAvatar>
                      <Avatar
                        variant="square"
                        alt={promoData.nomePromocao}
                        src={promoData.urlImagemPromocao}
                      />
                    </ListItemAvatar>
                    <Typography variant="h6">
                      {promoData.nomePromocao}
                      <Typography variant="body2" color="text.primary">
                        Data do Sorteio {formatDateTime(promoData.dataHora)}
                      </Typography>
                    </Typography>
                  </ListItem>
                }
                secondary={group.map((winner: any, winnerIndex: number) => (
                  <React.Fragment
                    key={`${winner.idPromocao}-${winner.dataHora}-${winnerIndex}`}
                  >
                    {/* Renderize os ganhadores para o mesmo sorteio */}
                    <ListItem alignItems="flex-start" sx={{ ml: 2 }}>
                      <ListItemAvatar>
                        <Avatar alt={winner.nome} src={winner.urlImagem} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            <Typography variant="h6">{winner.nome}</Typography>
                            <Typography variant="body1">
                              {winner.bairro}, {winner.cidade}-{winner.estado} |
                              Tel: {winner.celular}
                            </Typography>
                          </>
                        }
                        secondary={`Nº Cupom: ${formatCupomNumber(
                          winner.numeroCupom
                        )}`}
                      />
                    </ListItem>
                    {winnerIndex < group.length - 1 && (
                      <Divider variant="inset" component="li" />
                    )}
                  </React.Fragment>
                ))}
              />
            </ListItem>
            {index < Object.values(winners).length - 1 && (
              <Divider variant="inset" component="li" />
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default XsResults;
