import { Grid } from "@mui/material";
import PromotionsList from "../Promotions";
import { PromotionType } from "../../../../model/promotion";
import PromosCarousel from "../PromosCarousel";
import WinnersCarousel from "../WinnersCarousel";
import { WinnerType } from "../../../../model/winner";
import Rules from "../Rules";

interface HomeDataProps {
  promotions: PromotionType[];
  winners: WinnerType[];
}

const HomeData: React.FC<HomeDataProps> = ({ promotions, winners }) => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        padding={0}
        style={{ backgroundColor: "#eee" }}
      >
        {promotions && promotions.length >= 1 && (
          <>
            <Grid item md={6} xs={12}>
              <PromotionsList promotions={promotions} />
            </Grid>
            <Grid item md={6} xs={12}>
              <PromosCarousel promotions={promotions} />
            </Grid>
          </>
        )}
        {winners && winners.length >= 1 && (
          <Grid item md={6} xs={12}>
            <WinnersCarousel winners={winners} />
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <Rules />
        </Grid>
      </Grid>
    </>
  );
};

export default HomeData;
