import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useLocation } from "react-router";
import api from "../../services/api";
import { useAlert } from "react-alert";
import { useAuth } from "../../contexts/useAuth";

const validationSchema = yup.object({
  senha: yup
    .string()
    .min(6, "Sua senha deve ter no mínimo 6 caracteres")
    .required("Por favor digite sua nova senha"),
});

const Reset: React.FC = () => {
  const alert = useAlert();
  const location = useLocation();
  const { handleOpenLogin } = useAuth();

  const { pathname } = location;
  const token = pathname.replace("/auth/reset/", "");
  const formik = useFormik({
    initialValues: {
      senha: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const result = await api.post(`/auth/reset/${token}`, values);
      if (result.status === 200) {
        alert.success(result?.data?.message || "Senha alterada com sucesso", {
          onOpen: () => {
            handleOpenLogin();
          },
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={2}
        sx={{
          px: { xs: 6, md: 20, lg: 40 },
          py: { xs: 6, md: 10, lg: 10 },
          backgroundColor: "white",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h5">Redefinir minha senha</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="senha"
            name="senha"
            label="Senha"
            type="password"
            value={formik.values.senha}
            onChange={formik.handleChange}
            error={formik.touched.senha && Boolean(formik.errors.senha)}
            helperText={formik.touched.senha && formik.errors.senha}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button color="primary" variant="contained" fullWidth type="submit">
            Enviar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Reset;
