import {
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { IoLogInOutline, IoTicketOutline } from "react-icons/io5";
import { useAuth } from "../../contexts/useAuth";
import api from "../../services/api";
import HomeData from "./components/HomeData";

const Home: React.FC = () => {
  const history = useHistory();
  const { store, handleOpenLogin } = useAuth();
  const [promotions, setPromotions] = useState([]);
  const [winners, setWinners] = useState([]);

  const loadPromotions = useCallback(async () => {
    const result = await api.post(`/promotion`, {
      page: 1,
      limit: 10,
      status: 1,
    });
    if (result?.data) {
      setPromotions(result.data.rows);
    }
  }, []);

  const loadWinners = useCallback(async () => {
    const result = await api.post(`/winner`, {
      page: 1,
      limit: 10,
      status: 1,
      hasImage: true,
    });
    if (result?.data) {
      setWinners(result.data.rows);
    }
  }, []);

  useEffect(() => {
    loadPromotions();
  }, [loadPromotions]);

  useEffect(() => {
    loadWinners();
  }, [loadWinners]);

  const handleClickMainButton = useCallback(() => {
    if (store.isAuthenticated) {
      history.push("/register-coupon");
    } else {
      handleOpenLogin();
    }
  }, [store.isAuthenticated, history, handleOpenLogin]);

  return (
    <>
      <CssBaseline />
      <Container sx={{ backgroundColor: "white" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          padding={0}
          style={{ backgroundColor: "#0062A4", color: "white" }}
        >
          <Grid item md={6} xs={12} className="image-container">
            <img
              src="/images/home/posto.jpg"
              style={{ width: "100%" }}
              className="zoom-image"
              alt="Posto Portal Piracaia"
            />
          </Grid>

          <Grid item md={6} xs={12} pb={2} textAlign="center">
            <Typography
              gutterBottom
              variant="body1"
              className="animate__animated animate__bounceInRight"
              style={{
                fontSize: "3vh",
                textAlign: "center",
                padding: 2,
                fontWeight: "bold",
              }}
            >
              CONCORRA VOCÊ TAMBÉM
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              style={{
                fontSize: "2vh",
                textAlign: "center",
                padding: 6,
              }}
              className="animate__animated animate__flash animate__slow"
            >
              ABASTEÇA, CADASTRE OS SEUS <b>CUPONS FISCAIS</b> E A{" "}
              <b>CADA 30</b> LITROS GANHE <b>2 CUPONS</b> PARA PARTICIPAR DE
              NOSSAS PROMOÇÕES
            </Typography>
            <Button
              variant="contained"
              size="large"
              color="success"
              sx={{
                py: 4,
                px: 2,
                m: 4,
                fontSize: "2vh",
                borderRadius: 4,
              }}
              onClick={handleClickMainButton}
              startIcon={
                store.isAuthenticated ? (
                  <IoTicketOutline size="4vh" />
                ) : (
                  <IoLogInOutline size="4vh" />
                )
              }
            >
              <span className="animate__animated animate__pulse animate__slower animate__infinite">
                {store.isAuthenticated ? (
                  <b>CADASTRAR MEU CUPOM FISCAL</b>
                ) : (
                  <b>ENTRAR E CONCORRER</b>
                )}
              </span>
            </Button>
          </Grid>

          <Grid item md={12} xs={12} style={{ color: "#0062A4" }}>
            <HomeData promotions={promotions} winners={winners} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Home;
