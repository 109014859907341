import { Router } from "react-router";
import { AuthProvider } from "./contexts/useAuth";
import Routes from "./routes";
import history from "./services/history";
import GlobalStyles from "./styles/global";
import { positions, Provider } from "react-alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CustomAlertTemplate from "./components/CustomAlertTemplate";
import { useCallback, useEffect, useState } from "react";
import { Session } from "./model/session";
import { DrawProvider } from "./contexts/draw";
import { LocalStorageService } from "./services/localStorageService";
import LoginModal from "./pages/Login/Modal";

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 10000,
  color: "black",
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#0062A4", // Altere aqui a cor do primary color
    },
    secondary: {
      main: "#eb390c", // Altere aqui a cor do error color
    },
  },
});

const initialSession: Session = {
  redirectPath: "/",
  isAuthenticated: false,
  user: undefined,
};

function App() {
  const [loading, setLoading] = useState(true);
  const [storage, setStorage] = useState(initialSession);

  const loadLocalStorage = useCallback(() => {
    const session = LocalStorageService.getItem("session");
    if (session) {
      setStorage(session);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    loadLocalStorage();
  }, [loadLocalStorage]);

  return loading ? (
    <></>
  ) : (
    <ThemeProvider theme={theme}>
      <Provider template={CustomAlertTemplate} {...options}>
        <AuthProvider storage={storage}>
          <DrawProvider>
            <Router history={history}>
              <GlobalStyles />
              <Routes />
              <LoginModal history={history} />
            </Router>
          </DrawProvider>
        </AuthProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
