import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Grid, TextField, Typography } from "@mui/material";
import api from "../../services/api";
import { useAuth } from "../../contexts/useAuth";
import TextMaskCPF from "../../helpers/masks/TextMaskCPF";
import TextFieldMask from "react-text-mask";
import TextMaskCelular from "../../helpers/masks/TextMaskCelular";
import { useHistory } from "react-router-dom";
import TextMaskDate from "../../helpers/masks/TextMaskDate";
import TextMaskCep from "../../helpers/masks/TextMaskCep";
import { differenceInYears, isValid, parse } from "date-fns";
const ufsValidas = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];
const validationSchema = yup.object({
  nome: yup
    .string()
    .required("Favor digitar seu nome completo")
    .test(
      "possui-sobrenome",
      "O nome deve ter pelo menos um sobrenome",
      (value) => {
        if (!value) return false;
        const partesNome = value.trim().split(" ");
        return partesNome.length > 1;
      }
    ),
  cpf: yup.string().required("Favor digitar seu CPF"),
  email: yup
    .string()
    .email("Favor digitar um email válido")
    .required("Favor digitar seu E-mail"),
  celular: yup.string().required("Favor digitar seu número de Celular'"),
  dataNascimento: yup
    .string()
    .required("Data de nascimento é obrigatória")
    .test("valid-date", "Data de Nascimento inválida", (value) => {
      if (!value) return false;
      const dataNascimento = parse(value, "dd/MM/yyyy", new Date());
      return isValid(dataNascimento);
    })
    .test("idade", "Você precisa ter no mínimo 18 anos", (value) => {
      if (!value) return false;
      const dataNascimento = parse(value, "dd/MM/yyyy", new Date());
      const hoje = new Date();

      return differenceInYears(hoje, dataNascimento) >= 18;
    }),
  endereco: yup.string().required("Endereço é obrigatório"),
  bairro: yup.string().required("Bairro é obrigatório"),
  cep: yup
    .string()
    .required("CEP é obrigatório")
    .matches(/^\d{2}.\d{3}-\d{3}$/, "CEP inválido"),
  cidade: yup.string().required("Cidade é obrigatório"),
  estado: yup
    .string()
    .required("Estado é obrigatório")
    .uppercase()
    .oneOf(ufsValidas, "Estado inválido"),
  senha: yup
    .string()
    .min(6, "Sua senha deve ter no mínimo 6 caracteres")
    .required("Por favor digite sua senha"),
});

const Sign: React.FC = () => {
  const { signIn } = useAuth();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      nome: "",
      cpf: "",
      dataNascimento: "",
      email: "",
      celular: "",
      cep: "",
      cidade: "",
      estado: "",
      endereco: "",
      bairro: "",
      complemento: "",
      senha: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const response = await api.post("usuarios", values);

      if (response.status === 204) {
        await signIn({ login: values.cpf, password: values.senha });
        history.push("/");
      }
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent={"center"} mt={{ xs: 5, md: 10 }}>
          <Grid item md={9} xs={12}>
            <Grid
              container
              spacing={2}
              sx={{
                px: { xs: 5, md: 8 },
                py: { xs: 6, md: 10, lg: 10 },
                backgroundColor: "white",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h5">Cadastro</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="nome"
                  name="nome"
                  label="Nome Completo"
                  value={formik.values.nome}
                  onChange={formik.handleChange}
                  error={formik.touched.nome && Boolean(formik.errors.nome)}
                  helperText={formik.touched.nome && formik.errors.nome}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskCPF,
                    inputProps: {
                      component: TextFieldMask,
                    },
                  }}
                  fullWidth
                  id="cpf"
                  name="cpf"
                  label="CPF"
                  type="tel"
                  value={formik.values.cpf}
                  onChange={formik.handleChange}
                  error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                  helperText={formik.touched.cpf && formik.errors.cpf}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskDate,
                    inputProps: {
                      component: TextFieldMask,
                    },
                  }}
                  fullWidth
                  id="dataNascimento"
                  name="dataNascimento"
                  label="Data de Nascimento"
                  type="tel"
                  value={formik.values.dataNascimento}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.dataNascimento &&
                    Boolean(formik.errors.dataNascimento)
                  }
                  helperText={
                    formik.touched.dataNascimento &&
                    formik.errors.dataNascimento
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="E-Mail"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskCelular,
                    inputProps: {
                      component: TextFieldMask,
                    },
                  }}
                  fullWidth
                  id="celular"
                  name="celular"
                  label="Número do Celular"
                  type="tel"
                  value={formik.values.celular}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.celular && Boolean(formik.errors.celular)
                  }
                  helperText={formik.touched.celular && formik.errors.celular}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskCep,
                    inputProps: {
                      component: TextFieldMask,
                    },
                  }}
                  fullWidth
                  id="cep"
                  name="cep"
                  label="CEP"
                  type="tel"
                  value={formik.values.cep}
                  onChange={formik.handleChange}
                  error={formik.touched.cep && Boolean(formik.errors.cep)}
                  helperText={formik.touched.cep && formik.errors.cep}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  id="cidade"
                  name="cidade"
                  label="Cidade"
                  type="cidade"
                  value={formik.values.cidade}
                  onChange={formik.handleChange}
                  error={formik.touched.cidade && Boolean(formik.errors.cidade)}
                  helperText={formik.touched.cidade && formik.errors.cidade}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  inputProps={{
                    maxLength: 2,
                  }}
                  fullWidth
                  id="estado"
                  name="estado"
                  label="Estado"
                  type="estado"
                  value={formik.values.estado}
                  onChange={formik.handleChange}
                  error={formik.touched.estado && Boolean(formik.errors.estado)}
                  helperText={formik.touched.estado && formik.errors.estado}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="endereco"
                  name="endereco"
                  label="Endereço"
                  type="endereco"
                  value={formik.values.endereco}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco && Boolean(formik.errors.endereco)
                  }
                  helperText={formik.touched.endereco && formik.errors.endereco}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="bairro"
                  name="bairro"
                  label="Bairro"
                  type="bairro"
                  value={formik.values.bairro}
                  onChange={formik.handleChange}
                  error={formik.touched.bairro && Boolean(formik.errors.bairro)}
                  helperText={formik.touched.bairro && formik.errors.bairro}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="complemento"
                  name="complemento"
                  label="Complemento"
                  type="complemento"
                  value={formik.values.complemento}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.complemento &&
                    Boolean(formik.errors.complemento)
                  }
                  helperText={
                    formik.touched.complemento && formik.errors.complemento
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="senha"
                  name="senha"
                  label="Senha"
                  type="password"
                  value={formik.values.senha}
                  onChange={formik.handleChange}
                  error={formik.touched.senha && Boolean(formik.errors.senha)}
                  helperText={formik.touched.senha && formik.errors.senha}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Enviar Cadastro
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default Sign;
