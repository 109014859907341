import { Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import TextMaskDozen from "../../../../helpers/masks/TextMaskDozen";
import TextFieldMask from "react-text-mask";
import { DrawingType } from "../../../../model/drawing";
import { DrawService } from "../../../../services/drawService";
import { CouponHits, HitsDozens } from "../../../../model/hitsDozen";
import api from "../../../../services/api";
import { useAuth } from "../../../../contexts/useAuth";

interface DrawformProps {
  drawing: DrawingType;
  setDrawing: (d: DrawingType) => void;
}

const DrawForm: React.FC<DrawformProps> = ({ drawing, setDrawing }) => {
  const { store } = useAuth();
  const [blockDraw, setBlockDraw] = useState(true);

  useEffect(() => {
    setBlockDraw(!drawing?.promotion?.id);
    if (drawing) {
      setBlockDraw(drawing.mostCorrectDozens === 20);
    }
  }, [drawing]);

  const validationSchema = yup.object({
    dezena: yup
      .string()
      .required("Favor digitar o nº da DEZENA sorteada.")
      .test("test-dezena", "Dezena inválida", (value) => {
        return (
          value?.trim().length === 2 &&
          parseInt(value, 10) >= 1 &&
          parseInt(value, 10) <= 60
        );
      })
      .test("test-dezena-repetida", "Dezena já foi sorteada", (value) => {
        return (
          !!(
            value &&
            drawing?.balls.length &&
            !drawing?.balls.includes(value)
          ) || !drawing?.balls.length
        );
      }),
  });

  const getMostCorrectDozens = (coupons: CouponHits[]) => {
    return coupons.reduce((maiorAcertos: number, cupom: any) => {
      return cupom.acertos > maiorAcertos ? cupom.acertos : maiorAcertos;
    }, 0);
  };

  const updateDrawing = useCallback(
    (result: HitsDozens, balls: string[]) => {
      const acertos = getMostCorrectDozens(result.coupons);
      const updatedDrawing = {
        ...drawing,
        showWinners: false,
        couponsTotal: result.total || drawing?.amountCoupons || 0,
        balls,
        lastBall: balls[balls?.length - 1] || "",
        mostCorrectDozens: acertos,
        winners: acertos === 20 ? result?.coupons : undefined,
      };
      DrawService.updateDrawing(updatedDrawing);
    },
    [drawing]
  );

  const checkWinners = useCallback(
    async (balls: string[], callback?: Function) => {
      api
        .post<HitsDozens>(
          `/couponByDozens`,
          {
            idPromocao: drawing?.promotion.id,
            dataHoraSorteio: drawing?.promotion.dataProximoSorteio,
            dezenas: balls.join(","),
          },
          {
            headers: { "x-access-token": store.user?.token },
          }
        )
        .then((res) => {
          if (res?.data) {
            updateDrawing(res.data, balls);
            if (callback) callback();
          }
        });
    },
    [drawing, store.user?.token, updateDrawing]
  );

  const submitDozen = useCallback(
    async (values: { dezena: string }, { resetForm }) => {
      const balls = [...drawing.balls, values.dezena];
      await checkWinners(balls);
      resetForm();
    },
    [checkWinners, drawing.balls]
  );

  const clearDrawResults = useCallback(async () => {
    if (drawing && drawing?.mostCorrectDozens === 20) {
      await api.post(
        `/promotion/${drawing.promotion.id}/clearResults`,
        {
          dataHoraSorteio: drawing.promotion.dataProximoSorteio,
        },
        {
          headers: { "x-access-token": store.user?.token },
        }
      );
    }
  }, [drawing, store.user?.token]);

  const handleRemoveLastDozen = useCallback(async () => {
    if (drawing?.balls.length) {
      const newBalls = [...drawing.balls];
      newBalls.pop();
      await checkWinners(newBalls, clearDrawResults);
    }
  }, [checkWinners, clearDrawResults, drawing]);

  const formik = useFormik({
    initialValues: {
      dezena: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitDozen,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              inputComponent: TextMaskDozen,
              inputProps: {
                style: { fontSize: 80, textAlign: "center" },
                component: TextFieldMask,
              },
            }}
            variant="filled"
            fullWidth
            id="dezena"
            name="dezena"
            label="Nº da Dezena Sorteada"
            type="tel"
            disabled={blockDraw}
            value={formik.values.dezena}
            onChange={formik.handleChange}
            error={formik.touched.dezena && Boolean(formik.errors.dezena)}
            helperText={formik.touched.dezena && formik.errors.dezena}
          />
        </Grid>
        <Grid item xs={6} textAlign="center">
          <Button
            color="success"
            variant="contained"
            fullWidth
            type="submit"
            disabled={formik.isSubmitting || blockDraw}
          >
            CONFIRMAR DEZENA
          </Button>
        </Grid>
        <Grid item xs={6} textAlign="center">
          <Button
            color="error"
            variant="contained"
            fullWidth
            type="button"
            onClick={handleRemoveLastDozen}
            disabled={formik.isSubmitting || !drawing?.balls?.length}
          >
            REMOVER ÚLTIMA DEZENA
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DrawForm;
