import React from "react";

const TextMaskCep = React.forwardRef((props: any, ref) => {
  const { component: Component, ...other } = props;
  return (
    <Component
      {...other}
      mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
      placeholderChar="_"
      showMask={false}
    />
  );
});
export default TextMaskCep;
