import { Route, RouteProps } from "react-router";

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  redirectPath: string;
  loading: boolean;
} & RouteProps;

export default function ProtectedRoute({
  isAuthenticated,
  authenticationPath,
  redirectPath,
  loading,
  ...routeProps
}: ProtectedRouteProps) {
  return <Route {...routeProps} />;
}
