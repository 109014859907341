import React from "react";

// interface InputElement {
//   focus(): void;
//   value?: string;
// }

const TextMaskCPF = React.forwardRef((props: any, ref) => {
  const { component: Component, ...other } = props;

  // // implement `InputElement` interface
  // React.useImperativeHandle(ref, () => ({
  //   focus: () => {
  //     // logic to focus the rendered component from 3rd party belongs here
  //   },
  //   // hiding the value e.g. react-stripe-elements
  // }));

  return (
    <Component
      {...other}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ]}
      placeholderChar="_"
      showMask={false}
    />
  );
});

export default TextMaskCPF;
