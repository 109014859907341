import React, { useCallback, useEffect, useState } from "react";
// import { useAlert } from "react-alert";
import { Button, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { DrawService } from "../../../services/drawService";
import { DrawingType } from "../../../model/drawing";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import DrawDash from "./DrawDash";
import DrawForm from "./DrawForm";
import api from "../../../services/api";
import { useAuth } from "../../../contexts/useAuth";

let intervalId: NodeJS.Timeout;
type Params = {
  promotionId: string;
};

const DrawPromotion: React.FC = () => {
  const { store } = useAuth();
  const { promotionId } = useParams<Params>();
  const [drawing, setDrawing] = useState<DrawingType>();
  const [blockDraw, setBlockDraw] = useState(true);
  const [finished, setFinished] = useState(false);
  // const alert = useAlert();

  useEffect(() => {
    if (intervalId) clearInterval(intervalId);
    if (promotionId && !finished) {
      intervalId = setInterval(() => {
        setDrawing(DrawService.getDrawing(parseInt(promotionId, 10)));
      }, 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [finished, promotionId]);

  useEffect(() => {
    setBlockDraw(!drawing?.promotion?.id);
    if (drawing) {
      setBlockDraw(drawing.mostCorrectDozens === 20);
      setFinished(!drawing.promotion.status);
    }
  }, [drawing]);

  const handleShowWinners = () => {
    setDrawing((prev) => {
      const dw = {
        ...prev!,
        showWinners: !prev!.showWinners,
        showingWinner: 0,
      };
      DrawService.updateDrawing(dw);
      return dw;
    });
  };

  const handleNextWinner = () => {
    setDrawing((prev) => {
      if (prev?.showingWinner !== undefined) {
        const dw = {
          ...prev,
          showingWinner: prev.showingWinner + 1,
        };
        DrawService.updateDrawing(dw);
        return dw;
      }
    });
  };

  const handlePreviousWinner = () => {
    setDrawing((prev) => {
      if (prev?.showingWinner && prev?.showingWinner > 0) {
        const dw = {
          ...prev,
          showingWinner: prev.showingWinner - 1,
        };
        DrawService.updateDrawing(dw);
        return dw;
      }
    });
  };

  const handleFinishPromotion = useCallback(async () => {
    const result = await api.post(
      `/promotion/finish`,
      {
        id: drawing?.promotion.id,
      },
      {
        headers: { "x-access-token": store.user?.token },
      }
    );
    if (result && drawing) {
      DrawService.updateDrawing({
        ...drawing,
        promotion: { ...drawing.promotion, status: 0 },
      });
    }
  }, [drawing, store.user?.token]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        px: { xs: 6, md: 10, lg: 20 },
        py: { xs: 6, md: 10, lg: 10 },
        my: 1,
        backgroundColor: "white",
      }}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography variant="h5">Sorteio ao vivo</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={7}>
            {drawing && <DrawDash drawing={drawing} />}
          </Grid>
          <Grid item xs={5}>
            {drawing && <DrawForm drawing={drawing} setDrawing={setDrawing} />}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} hidden={!blockDraw}>
        <Grid container spacing={2} marginTop={4} justifyContent={"center"}>
          <Grid
            item
            xs={12}
            marginTop={4}
            textAlign="center"
            hidden={!drawing?.showWinners!}
          >
            <Typography
              variant="h5"
              className="animate__animated animate__flash animate__infinite animate__slower"
            >
              Mostrando Ganhador {drawing?.showingWinner! + 1} de{" "}
              {drawing?.winners?.length}
            </Typography>
            <Button
              color={"primary"}
              variant="contained"
              type="button"
              disabled={drawing?.showingWinner === 0}
              onClick={handlePreviousWinner}
              startIcon={<MdChevronLeft />}
            >
              Anterior
            </Button>
            <Button
              color={"primary"}
              variant="contained"
              type="button"
              disabled={
                drawing?.showingWinner! + 1 === drawing?.winners?.length
              }
              onClick={handleNextWinner}
              endIcon={<MdChevronRight />}
            >
              Próximo
            </Button>
          </Grid>

          <Grid item xs={3} textAlign="center">
            <Button
              color={drawing?.showWinners ? "primary" : "error"}
              variant="contained"
              fullWidth
              type="button"
              onClick={handleShowWinners}
            >
              {drawing?.showWinners
                ? "OCULTAR GANHADORES"
                : "MOSTRAR GANHADORES"}
            </Button>
          </Grid>

          <Grid item xs={3} textAlign="center">
            <Button
              color={"warning"}
              variant="contained"
              fullWidth
              type="button"
              disabled={finished}
              onClick={handleFinishPromotion}
            >
              FINALIZAR SORTEIO
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DrawPromotion;
