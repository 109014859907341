import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { PromotionType } from "../../../../model/promotion";

interface PromosCarouselProps {
  promotions: PromotionType[];
}

const PromosCarousel: React.FC<PromosCarouselProps> = ({ promotions }) => {
  return (
    <>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={50}
        totalSlides={promotions.filter((p) => p.urlBanner|| p.urlCupom || p.urlThumb).length}
        visibleSlides={1}
        infinite
        isPlaying={true}
      >
        <Slider>
          {promotions
            .filter((p) =>  p.urlBanner|| p.urlCupom || p.urlThumb)
            .map((promotion, index) => (
              <Slide index={index} key={promotion.id}>
                <img
                  width={"100%"}
                  src={promotion.urlBanner|| promotion.urlCupom || promotion.urlThumb}
                  alt={promotion.nome}
                />
              </Slide>
            ))}
        </Slider>
      </CarouselProvider>
    </>
  );
};

export default PromosCarousel;
