import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Rules() {
  return (
    <Box
      sx={{
        height: { md: "23vw", xs: 300 },
        width: "100%",
        p: 2,
        overflow: "scroll",
      }}
    >
      <Typography variant="h6" gutterBottom textAlign={"center"}>
        Regulamento do Show de Prêmios
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        O Posto Portal Piracaia, proporciona aos clientes que abastecer, a
        oportunidade para quem desejar em participar dos nossos sorteios,
        através do show de prêmios, o qual irá disponibilizar a entrega dos
        prêmios principais de 02 (duas) motos Zero KM conforme regulamento,
        sendo que cada moto possui valor fixo estimado em R$ 18.000,00 (Dezoito
        Mil Reais), onde havendo mais de um ganhador, poderá o prêmio ser
        fracionado entre os ganhadores dos sorteios que irão ocorrer nos dias
        15/06/2024 e 14/06/2025 às 18h. <br />
        Abaixo as regras para participação:
      </Typography>

      <Typography variant="body2" gutterBottom style={{ textAlign: "justify" }}>
        1) Fica a critério e por livre espontânea vontade a participação de
        qualquer cliente pessoa física, pessoa jurídica deverá realizar o
        cadastro através de dados por uma pessoa física, SEMPRE, com idade igual
        ou maior a 18 (dezoito) anos completos;
        <br />
        2) Deverá o cliente realizar cadastro no site
        www.postoportalpiracaia.com.br, para registrar os seus abastecimentos,
        validando o cupom e concorrendo;
        <br />
        3) Cada cliente deverá criar sua senha, que é pessoal e intransferível e
        não enviamos nenhuma comunicação, por qualquer meio de comunicação,
        solicitando confirmações de dados;
        <br />
        4) Os clientes devidamente cadastrados, poderão realizar o registro dos
        cupons de abastecimentos os quais irão permitir a concorrer as promoções
        válidas / vigentes.
        <br />
        5) Os clientes ao registrarem os seus cupons de abastecimentos, após
        validado, será computado por soma a quantidade de litros de combustíveis
        do cupom, sendo que a cada 30 litros, automaticamente o sistema irá
        descontar do total de litros e irá gerar cupons para participar das
        promoções válidas / vigentes, além das duas motos.
        <br />
        6) O Posto Portal a qualquer momento poderá disponibilizar novas
        promoções, com produtos diferentes a serem disponibilizados aos nossos
        clientes como brinde.
        <br />
        7) Havendo registro dos cupons de abastecimentos no site do Posto Portal
        Piracaia no Show de Prêmios, estando válidas e/ou vigentes as promoções,
        o sistema irá disponibilizar a cada 30 (trinta) litros 01 cupom para
        concorrer a cada moto e 02 cupons para as demais promoções vigentes no
        período do registro, vide apresentação no site com data dos respectivos
        sorteios.
        <br />
        8) Sorteio do Show de prêmios se dará automaticamente pelo sistema,
        validando os cupons para os respectivos sorteios vigentes, sendo que o
        sorteio das motos se dará presencialmente, através de sorteios
        sequenciais das dezenas de 01 até 60, sorteada pelo próprio publico
        presente, sendo registrado, sequencialmente uma a uma no sistema as
        respectivas dezenas sorteadas, vindo o sistema informar quando o(s)
        primeiro(s) ganhador(es) possuírem o cupom com todas as dezenas
        sorteadas.
        <br />
        9) Havendo cadastramento de novas promoções, não irá prejudicar em
        nenhuma hipótese a participação do cliente para concorrer ao prêmio
        principal, que terão cupons com os grupos de dezenas garantidas para o
        grande sorteio.
        <br />
        10) A divulgação se dará no ato da Apuração, além de comunicação direta
        ao contemplado pessoalmente, por telefone e demais meios de comunicação
        disponíveis (Redes Sociais, Lives, E-mails, Facebook, Whatsapp e
        Instagram).
        <br />
        11) O prêmio será entregue ao titular do cupom contemplado, no local da
        Apuração (Posto Portal Piracaia), no prazo de até 30 (trinta) dias após
        a realização da apuração, livre de qualquer ônus, sendo que, o ganhador
        ao efetuar a retirada da moto, deverá arcar com as despesas com
        licenciamento, seguro obrigatório, IPVA, quando da realização do
        emplacamento e registro em nome do ganhador da Moto.
        <br />
        12) O valor de cada moto está estimado em R$ 18.000,00 (Dezoito mil
        reais), sendo que, se houver mais de um ganhador em cada sorteio,
        poderão fracionar o prêmio entre eles. <br />
        13) Os Ganhadores se comprometem e deverão comprovar sua identidade,
        mediante documentação (RG, CPF e Comprovante de Residência), bem como
        assinar a declaração de recebimento do prêmio e carta de compromisso,
        devendo ser entregues cópias autenticadas do RG, CPF e COMPROVANTE DE
        RESIDÊNCIA ATUAL. <br />
        14) Os Ganhadores deverão apresentar em até 05 (cindo) dias, os
        documentos necessários exigidos para realização da aquisição da Moto
        (prêmio), recebendo assim, a Moto 0KM, no Posto Portal Piracaia.
        <br />
        15) Os Ganhadores autorizam, desde já, como consequência da conquista
        dos prêmios, que a Mandatária e/ou suas agências, conforme previsão na
        LGPD, venham utilizar seu nome, imagem e som de voz, em qualquer um dos
        meios escolhidos, para divulgação dessa promoção pelo período de até 03
        (três) anos, após a data de apuração, sem que isso traga nenhum tipo de
        ônus para a empresa promotora.
        <br />
        16) Ao receber o cupom, realizar o cadastro, registrar o abastecimento,
        o participante se declara conhecedor do regulamento dessa promoção, que
        estará afixada na sede da Mandatária, aceitando por completo, não
        podendo alegar ignorância quanto a sua existência.
        <br />
        17) Havendo instabilidade para o registro do cupom no sistema, poderá o
        cliente registrar posteriormente, não ficando a mandatária, obrigada com
        as demais promoções, caso não esteja vigente, exceto ao prêmio das duas
        motos.
        <br />
        18) Prazo final para cadastramento dos cupons para concorrer as duas
        motos, se encerra as 22:00 hs do dia que antecede o sorteio.
        <br />
      </Typography>
    </Box>
  );
}
