import * as React from "react";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { MdMenu } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/useAuth";
import { Divider } from "@mui/material";
import api from "../../services/api";

const pages = [
  { id: 1, title: "Home", url: "/", private: false },
  { id: 2, title: "Meus Cupons", url: "/coupons", private: true },
  { id: 3, title: "Cupom Fiscal", url: "/register-coupon", private: true },
  { id: 4, title: "Ganhadores", url: "/results" },
];
const settings = [
  { id: 1, title: "Meus Cupons", url: "/coupons", private: true },
  { id: 2, title: "Sorteio", url: "/admin/draw", private: true, admin: true },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const history = useHistory();
  const { store, signOut, setStore, handleOpenLogin } = useAuth();
  const [admin, setAdmin] = useState(false);

  // const master = (process.env?.REACT_APP_ID_USER_MASTER || "")
  //   .split(",")
  //   .map((id) => parseInt(id, 10));

  useEffect(() => {
    const admins = (process.env?.REACT_APP_ID_USER_ADMIN || "")
      .split(",")
      .map((id) => parseInt(id, 10));

    setAdmin(store.user?.id ? admins.includes(store.user?.id) : false);
  }, [store.user?.id]);

  const refreshUser = async () => {
    const result = await api.get(`/usuario`, {
      headers: { "x-access-token": store.user?.token },
    });
    if (result?.data) {
      setStore({
        ...store,
        user: { ...result.data, token: store?.user?.token },
      });
    }
  };

  const handleLog = async () => {
    if (store?.isAuthenticated) {
      signOut();
      history.push("/");
    } else {
      handleOpenLogin();
    }
    setAnchorElNav(null);
    setAnchorElUser(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (url: string) => {
    setAnchorElNav(null);
    history.push(url);
  };

  const handleCloseUserMenu = (url: string) => {
    setAnchorElUser(null);
    history.push(url);
  };

  return (
    <AppBar position="static" color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            sx={{ display: { xs: "none", md: "flex" }, mr: 1, ml: 2 }}
          >
            <img
              onClick={() => history.push("/")}
              src="/images/logo/logo.png"
              alt=""
              height="48"
            />
          </Typography>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MdMenu />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map(
                (page) =>
                  ((page.private && store?.isAuthenticated) ||
                    !page.private) && (
                    <MenuItem
                      key={page.id}
                      onClick={() => handleCloseNavMenu(page.url)}
                    >
                      <Typography
                        style={{ textTransform: "capitalize" }}
                        textAlign="center"
                      >
                        {page.title}
                      </Typography>
                    </MenuItem>
                  )
              )}
            </Menu>
          </Box>

          <Typography
            sx={{
              display: { xs: "flex", md: "none" },
              mr: 1,
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            <img
              onClick={() => history.push("/")}
              src="/images/logo/logo.png"
              alt=""
              height="36"
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map(
              (page) =>
                ((page.private && store?.isAuthenticated) || !page.private) && (
                  <Button
                    key={page.id}
                    onClick={() => handleCloseNavMenu(page.url)}
                    style={{ textTransform: "capitalize" }}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page.title}
                  </Button>
                )
            )}
          </Box>

          <Box sx={{ flexGrow: 0, mr: 2 }}>
            <Tooltip title="Menu Conta">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={store?.user?.nome} src={store?.user?.email} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(
                (setting) =>
                  ((setting.private && store?.isAuthenticated) ||
                    !setting.private) && (
                    <MenuItem
                      key={setting.id}
                      onClick={() => handleCloseUserMenu(setting.url)}
                      style={{
                        display: !setting.admin || admin ? "flex" : "none",
                      }}
                    >
                      <Typography
                        textAlign="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {setting.title}
                      </Typography>
                    </MenuItem>
                  )
              )}
              {store?.isAuthenticated && (
                <MenuItem key="balance" onClick={refreshUser}>
                  <Typography
                    textAlign="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Saldo:{" "}
                    {store.user?.saldoLitros.toString().replace(".", ",") ||
                      "0,00"}{" "}
                    litros
                  </Typography>
                </MenuItem>
              )}
              <Divider />
              <MenuItem key="logoff" onClick={handleLog}>
                <Typography
                  textAlign="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {store?.isAuthenticated ? "Sair" : "Entrar"}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
