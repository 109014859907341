import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Grid, TextField, Typography } from "@mui/material";
import api from "../../services/api";
import { useAlert } from "react-alert";
import { useAuth } from "../../contexts/useAuth";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Favor digitar um email válido")
    .required("Favor digitar seu E-mail"),
});

const Forgot: React.FC = () => {
  const alert = useAlert();
  const { handleOpenLogin } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const result = await api.post("/auth/forgot", values);
      if (result.status === 200) {
        alert.success(
          result?.data?.message ||
            "Enviamos um email pra você com um link para redefinir sua senha",
          {
            onClose: () => {
              handleOpenLogin();
            },
          }
        );
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent={"center"} mt={{ xs: 5, md: 20 }}>
        <Grid
          container
          spacing={2}
          md={5}
          xs={12}
          sx={{
            px: { xs: 5, md: 8 },
            py: { xs: 6, md: 10, lg: 10 },
            backgroundColor: "white",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h5">Redefinir minha senha</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="E-Mail"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button color="primary" variant="contained" fullWidth type="submit">
              Solicitar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Forgot;
