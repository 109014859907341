import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { WinnerType } from "../../../../model/winner";

interface WinnersCarouselProps {
  winners: WinnerType[];
}

const WinnersCarousel: React.FC<WinnersCarouselProps> = ({ winners }) => {
  return (
    <>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={50}
        totalSlides={winners.filter((w) => w.urlImagem).length}
        visibleSlides={1}
        infinite
        isPlaying={true}
        interval={7330}
      >
        <Slider>
          {winners
            .filter((w) => w.urlImagem)
            .map((winner, index) => (
              <Slide index={index} key={winner.numeroCupom}>
                <img width={"100%"} src={winner.urlImagem} alt={winner.nome} />
              </Slide>
            ))}
        </Slider>
      </CarouselProvider>
    </>
  );
};

export default WinnersCarousel;
