import { Box } from "@mui/material";
import styled from "styled-components";

export const BigBall = styled(Box)`
  position: fixed;
  top: 320px;
  left: 790px;
  z-index: 1;
  width: 320px;
  height: 320px;
  background-image: url("/images/show/ball.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const SmallBall = styled(Box)`
  width: 72px;
  height: 72px;
  background-image: url("/images/show/ball.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 10px;
  margin-bottom: 10px;
`;

interface SphereTextProps {
  fontSize?: string;
}

export const SphereText = styled.p<SphereTextProps>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "180px")};
  text-align: center;
  font-weight: bold;
  color: #000;
  transform: perspective(1000px) rotateX(25deg);
`;

export const DozenPanel = styled(Box)`
  position: fixed;
  bottom: 60px;
  left: 130px;
  z-index: 2;
  width: 1660px;
  height: 240px;
  text-align: left;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;
