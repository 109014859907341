export class LocalStorageService {
  static readonly storageName = "ppp-raffle:";

  static setItem(key: string, value: any) {
    try {
      localStorage.setItem(`${this.storageName + key}`, JSON.stringify(value));
    } catch (error) {
      console.error("Error saving to localStorage:", error);
    }
  }

  static getItem(key: string) {
    try {
      const item = localStorage.getItem(`${this.storageName + key}`);
      return item ? JSON.parse(item) : undefined;
    } catch (error) {
      console.error("Error getting data from localStorage:", error);
      return null;
    }
  }

  static removeItem(key: string) {
    try {
      localStorage.removeItem(`${this.storageName + key}`);
    } catch (error) {
      console.error("Error removing data from localStorage:", error);
    }
  }
}
