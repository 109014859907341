import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useAuth } from "../../contexts/useAuth";
import TextMaskCPF from "../../helpers/masks/TextMaskCPF";
import TextFieldMask from "react-text-mask";
import { cpf as validateCPF } from "cpf-cnpj-validator";
import * as H from "history";

const validationSchema = yup.object({
  login: yup
    .string()
    .required("CPF é obrigatório")
    .test("cpf-valido", "Favor digitar um CPF válido", (value) => {
      if (!value) return false; // Não Permite que o campo seja vazio
      return validateCPF.isValid(value);
    }),
  senha: yup
    .string()
    .min(6, "Sua senha deve ter no mínimo 6 caracteres")
    .required("Por favor digite sua senha"),
});

interface LoginProps {
  history: H.History;
}

const Login: React.FC<LoginProps> = ({ history }) => {
  const { signIn, handleCloseLogin } = useAuth();
  const formik = useFormik({
    initialValues: {
      login: "",
      senha: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await signIn({ login: values.login, password: values.senha });
      handleCloseLogin();
      // history.push(store.redirectPath);
    },
  });

  const handleClick = () => {
    handleCloseLogin();
    history.push("/forgot");
  };

  const handleSignInClick = () => {
    handleCloseLogin();
    history.push("/sign");
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: "white",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h5">Entrar</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                InputProps={{
                  inputComponent: TextMaskCPF,
                  inputProps: {
                    component: TextFieldMask,
                  },
                }}
                fullWidth
                id="login"
                name="login"
                label="Digite seu CPF"
                type="tel"
                value={formik.values.login}
                onChange={formik.handleChange}
                error={formik.touched.login && Boolean(formik.errors.login)}
                helperText={formik.touched.login && formik.errors.login}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="senha"
                name="senha"
                label="Senha"
                type="password"
                value={formik.values.senha}
                onChange={formik.handleChange}
                error={formik.touched.senha && Boolean(formik.errors.senha)}
                helperText={formik.touched.senha && formik.errors.senha}
              />
              <Button onClick={handleClick}>Esqueci minha senha</Button>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Entrar
              </Button>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                color="warning"
                variant="outlined"
                onClick={handleSignInClick}
              >
                Ainda não sou cadastrado
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Login;
