import { Container, Grid } from "@mui/material";
import React from "react";
import DrawPromotionsList from "./DrawPromotionList";

const Draw: React.FC = () => {
  return (
    <Container sx={{ backgroundColor: "white" }}>
      <Grid container>
        <Grid item md={12} pb={2} textAlign="center"></Grid>
        <DrawPromotionsList />
      </Grid>
    </Container>
  );
};

export default Draw;
