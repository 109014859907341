import { Container } from "@mui/material";
import React from "react";

const LayoutWrapper: React.FC = ({ children }) => {
  return (
    <Container
      component="main"
      sx={{ m: 0, p: 0, position: "fixed", top: 0, left: 0, bgcolor: "black" }}
    >
      {children}
    </Container>
  );
};

export default LayoutWrapper;
