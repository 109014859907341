import React, { createContext, useContext, useMemo, useState } from "react";

interface DrawContextType {
  balls: string[];
  updateBalls: (newValue: string[]) => void;
  lastBall: string;
  updateLastBall: (ball: string) => void;
}

export const DrawContext = createContext<DrawContextType | undefined>(
  undefined
);

const DrawProvider: React.FC = ({ children }) => {
  const [balls, setBalls] = useState<string[]>([]);
  const [lastBall, setLastBall] = useState<string>("");

  const updateBalls = (newValue: string[]) => {
    setBalls(newValue);
  };

  const updateLastBall = (ball: string) => {
    setLastBall(ball);
  };

  const contextValue: DrawContextType = useMemo(
    () => ({
      balls,
      updateBalls,
      lastBall,
      updateLastBall,
    }),
    [balls, lastBall]
  );

  return (
    <DrawContext.Provider value={contextValue}>{children}</DrawContext.Provider>
  );
};

function useDraw(): DrawContextType {
  const context = useContext(DrawContext)!;

  if (!context) {
    throw new Error("useDraw must be used within an DrawProvider");
  }
  return context;
}

export { DrawProvider, useDraw };
