import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import api from "../../services/api";
import { useAuth } from "../../contexts/useAuth";
import { Grid, TablePagination, Typography } from "@mui/material";
import Coupon from "./components/Coupon";
import { CouponType } from "../../model/coupon";
import Filter from "./components/Filter/index/index";
import { IoTicketOutline } from "react-icons/io5";

export default function CouponsReserve() {
  const { store } = useAuth();
  const [rows, setRows] = useState<Array<CouponType>>([]);

  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState("concorrendo");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const loadCoupons = useCallback(async () => {
    if (store.user?.token) {
      const result = await api.post(
        `/coupon`,
        { page: page + 1, limit: rowsPerPage, filter },
        {
          headers: { "x-access-token": store.user?.token },
        }
      );
      if (result?.data) {
        setRows(result.data.rows);
        setTotalRows(result.data.total);
      }
    }
  }, [store.user?.token, page, rowsPerPage, filter]);

  useEffect(() => {
    loadCoupons();
  }, [loadCoupons]);

  function defaultLabelDisplayedRows({ from, to, count }: any) {
    return `${from}-${to} de ${count !== -1 ? count : `maior que ${to}`}`;
  }

  const handleChangeFilter = (filter: string) => {
    setFilter(filter);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ width: "100%", pt: 4 }}>
        <Grid container justifyContent={"space-between"} sx={{ mb: 4 }}>
          <Grid item>
            <Typography variant="h5" sx={{ ml: 2 }}>
              <IoTicketOutline />
              &nbsp; Meus Cupons
            </Typography>
          </Grid>
          <Grid item sx={{ mr: 2 }}>
            <Filter onChange={handleChangeFilter} filter={filter} />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={{ xs: 6, md: 4 }}
          columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          paddingX={2}
          textAlign={"center"}
        >
          {rows.map((row) => (
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              key={row.numeroCupom}
              textAlign={"center"}
            >
              <Coupon coupon={row} />
            </Grid>
          ))}
        </Grid>

        <TablePagination
          labelRowsPerPage="Total por Página"
          labelDisplayedRows={defaultLabelDisplayedRows}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
