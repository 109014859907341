import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import api from "../../services/api";
import {
  TablePagination,
  Avatar,
  Grid,
  Typography,
  Hidden,
} from "@mui/material";
import { GroupWinners } from "../../model/winner";
import { formatCupomNumber, formatDateTime } from "../../services/helper";
import { IoTrophyOutline } from "react-icons/io5";
import XsResults from "./components/XsResults";
import { useAuth } from "../../contexts/useAuth";

export default function Results() {
  const [rows, setRows] = useState<GroupWinners>({});

  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { store } = useAuth();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const loadWinners = useCallback(async () => {
    const result = await api.post(`/winner`, {
      page: page + 1,
      limit: rowsPerPage,
    },
    {
      headers: { "x-access-token": store.user?.token },
    }
  );
    if (result?.data) {
      const groupedData = result.data.rows.reduce(
        (acc: any, currentValue: any) => {
          const { idPromocao, dataHora } = currentValue;
          const key = idPromocao + "-" + dataHora;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(currentValue);
          return acc;
        },
        {}
      );
      setRows(groupedData);
      setTotalRows(result.data.total);
    }
  }, [store.user?.token, page, rowsPerPage]);

  useEffect(() => {
    loadWinners();
  }, [loadWinners]);

  function defaultLabelDisplayedRows({ from, to, count }: any) {
    return `${from}-${to} de ${count !== -1 ? count : `maior que ${to}`}`;
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Grid container justifyContent={"space-between"} sx={{ mt: 4 }}>
        <Grid item>
          <Typography variant="h5" sx={{ ml: 2 }}>
            <IoTrophyOutline />
            &nbsp;Últimos Ganhadores
          </Typography>
          <Typography variant="body1" sx={{ ml: 2 }}>
            &nbsp;Prazo para retirada do prêmio até <b>"60 dias"</b> da data do sorteio
          </Typography>
        </Grid>
      </Grid>
      <Hidden mdUp>
        <XsResults winners={rows} />
      </Hidden>
      <Hidden mdDown>
        <TableContainer>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="Ganhadores">
            <TableHead sx={{ fontWeight: "bold" }}>
              <TableRow>
                <TableCell align="left" colSpan={2}>
                  Promoção
                </TableCell>
                <TableCell align="left">Data do Sorteio</TableCell>
                <TableCell align="left">Nº Cupom Contemplado</TableCell>
                <TableCell align="left">Nome Ganhador</TableCell>
                <TableCell align="left">Final Telefone</TableCell>
                <TableCell align="left">Cidade</TableCell>
                <TableCell align="left">Bairro</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(rows).map((group, index) => {
                return (
                  <React.Fragment key={`${group[0].idPromocao}-${index}`}>
                    {group.map((row: any, rowIndex: number) => {
                      return (
                        <TableRow
                          key={`${row.nome}-${row.numeroCupom}-${rowIndex}`}
                          hover={group.length === 1}
                        >
                          {/* Renderize as células da tabela, pulando as 3 primeiras células para o rowspan */}
                          {rowIndex === 0 && (
                            <>
                              <TableCell align="left" rowSpan={group.length}>
                                <Avatar
                                  alt={row.nomePromocao}
                                  src={row.urlImagemPromocao}
                                ></Avatar>
                              </TableCell>
                              <TableCell align="left" rowSpan={group.length}>
                                {row.nomePromocao}
                              </TableCell>
                              <TableCell align="left" rowSpan={group.length}>
                                {formatDateTime(row.dataHora)}
                              </TableCell>
                            </>
                          )}
                          <TableCell align="left">
                            {formatCupomNumber(row.numeroCupom)}
                          </TableCell>
                          <TableCell align="left">{row.nome}</TableCell>
                          <TableCell align="left">{row.celular}</TableCell>
                          <TableCell align="left">
                            {row.cidade} - {row.estado}
                          </TableCell>
                          <TableCell align="left">{row.bairro}</TableCell>
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      <TablePagination
        labelRowsPerPage="Total por Página"
        labelDisplayedRows={defaultLabelDisplayedRows}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
