import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import api from "../../../services/api";
import {
  TablePagination,
  Avatar,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import {
  formatCupomNumber,
  formatDateTime,
  stringAvatar,
} from "../../../services/helper";
import { IoGlobeOutline } from "react-icons/io5";
import { PromotionType } from "../../../model/promotion";
import { GiTrophy } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import { DrawService } from "../../../services/drawService";
import { useAuth } from "../../../contexts/useAuth";

const DrawPromotionsList: React.FC = () => {
  const history = useHistory();
  const { store } = useAuth();
  const [rows, setRows] = useState<Array<PromotionType>>([]);

  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const loadPromotions = useCallback(async () => {
    const result = await api.post(`/promotion`, {
      page: 1,
      limit: 10,
      status: 1,
      tipoSorteio: "globo",
    });
    if (result?.data) {
      setRows(result.data.rows);
      setTotalRows(result.data.total);
    }
  }, []);

  useEffect(() => {
    loadPromotions();
  }, [loadPromotions]);

  function defaultLabelDisplayedRows({ from, to, count }: any) {
    const greater = `maior que ${to}`;
    return `${from}-${to} de ${count !== -1 ? count : greater}`;
  }

  const startDraw = async (promotion: PromotionType) => {
    const result = await api.post(
      `/promotion/${promotion.id}/start`,
      {
        dataHoraSorteio: promotion.dataProximoSorteio,
      },
      {
        headers: { "x-access-token": store.user?.token },
      }
    );
    return result?.data?.total || null;
  };

  async function openShow(promotion: PromotionType) {
    const couponsTotal = await startDraw(promotion);
    if (couponsTotal) {
      DrawService.updateDrawing({
        promotion,
        amountCoupons: couponsTotal,
        couponsTotal,
        balls: [],
        lastBall: "",
        mostCorrectDozens: 0,
      });

      const newWindow = window.open(
        `/admin/${promotion.id}/show`,
        `show-${promotion.id}`,
        "popup,fullscreen=yes,menubar=no,toolbar=no,location=no,status=no,width=1920,height=1080"
      );

      // Verificar se o navegador suporta tela cheia
      if (newWindow?.document) {
        if (newWindow.document.documentElement.requestFullscreen) {
          newWindow.document.documentElement.requestFullscreen();
        }
      }
      history.push(`/admin/${promotion.id}/draw-promotion`);
    }
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Grid container justifyContent={"space-between"} sx={{ mt: 4 }}>
        <Grid item>
          <Typography variant="h5" sx={{ ml: 2 }}>
            <IoGlobeOutline />
            &nbsp;Sorteios a serem realizados
          </Typography>
        </Grid>
      </Grid>
      <TableContainer>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="promocoes">
          <TableHead sx={{ fontWeight: "bold" }}>
            <TableRow>
              <TableCell align="left" colSpan={2}>
                Promoção
              </TableCell>
              <TableCell align="left">Data do Sorteio</TableCell>
              <TableCell align="left">Valor Sugerido</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                hover
              >
                <TableCell align="left">
                  <Avatar
                    {...stringAvatar(row.nome)}
                    alt={row.nome}
                    src={row.urlThumb}
                  >
                    {!row.urlThumb && <GiTrophy />}
                  </Avatar>
                </TableCell>
                <TableCell align="left">{row.nome}</TableCell>
                <TableCell align="left">
                  {formatDateTime(row.dataProximoSorteio)}
                </TableCell>
                <TableCell align="left">
                  {formatCupomNumber(row.valorSugerido)}
                </TableCell>
                <TableCell align="left">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => openShow(row)}
                  >
                    Abrir
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Total por Página"
        labelDisplayedRows={defaultLabelDisplayedRows}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DrawPromotionsList;
