import { Grid, Typography } from "@mui/material";
import { PromotionType } from "../../../../model/promotion";
import { formatDateTime } from "../../../../services/helper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Lottie from "react-lottie";
import animation from "../../../../assets/coupon.json";
interface PormotionsListProps {
  promotions: PromotionType[];
}

const PromotionsList: React.FC<PormotionsListProps> = ({ promotions }) => {
  return (
    <>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item>
          <Lottie
            options={{
              animationData: animation,
              autoplay: true,
              loop: true,
            }}
            width={60}
            isStopped={false}
            isPaused={false}
          />
        </Grid>
        <Grid item>
          <Typography
            gutterBottom
            variant="body1"
            style={{
              fontSize: "2.5vh",
              textAlign: "center",
              fontWeight: "bold",
            }}
            className="animate__animated animate__bounceIn"
          >
            PRÓXIMOS SORTEIOS
          </Typography>
        </Grid>
        <Grid item>
          <Lottie
            options={{
              animationData: animation,
              autoplay: true,
              loop: true,
            }}
            width={60}
            isStopped={false}
            isPaused={false}
          />
        </Grid>
      </Grid>

      <List sx={{ width: "100%" }}>
        {promotions &&
          promotions.length > 0 &&
          promotions.map((promotion, index) => (
            <ListItem
              key={promotion.id}
              className={`animate__animated animate__bounceInRight animate__delay-${
                1 + index
              }s`}
            >
              <ListItemAvatar>
                <Avatar
                  alt={promotion.nome}
                  src={
                    promotion.urlThumb ||
                    promotion.urlBanner ||
                    promotion.urlCupom
                  }
                ></Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="h5">{promotion.nome}</Typography>}
                secondary={formatDateTime(promotion.dataProximoSorteio)}
              />
            </ListItem>
          ))}
      </List>
    </>
  );
};

export default PromotionsList;
