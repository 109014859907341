import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import api from "../../services/api";
import { useAlert } from "react-alert";
import { useAuth } from "../../contexts/useAuth";
import TextFieldMask from "react-text-mask";
import TextMaskInteger from "../../helpers/masks/TextMaskInteger";
import TextMaskCurrency from "../../helpers/masks/TextMaskCurreny";
// import TextMaskLiters from "../../helpers/masks/TextMaskLiters";
import TextMaskDateTime from "../../helpers/masks/TextMaskDateTime";

const validationSchema = yup.object({
  cupomFiscal: yup.string().required("Favor digitar o nº do Cupom Fiscal"),
  dataCompra: yup
    .string()
    .required("Favor digitar a Data e Hora do Cupom Fiscal"),
  // qtde: yup.string().required("Favor digitar a quantide de litros"),
  valorPago: yup.string().required("Favor digitar o valor total pago"),
  tipoProduto: yup
    .string()
    .test("valid-tipo", "Favor selecionar o Tipo do Combustível", (value) => {
      if (!value) return false;
      return true;
    })
    .required("Favor selecionar o Tipo do Combustível"),
  codBar: yup
    .string()
    .required(
      "Favor digitar os 6 últimos dígitos do Código de Barras do Cupom Fiscal"
    )
    .test(
      "text-cod-bar",
      "Digíte os 6 últimos dígitos do Código de barras",
      (value) => {
        return value?.trim().length === 6;
      }
    ),
});

const products = [
  { id: "etanol", nome: "Etanol" },
  { id: "gasolina", nome: "Gasolina" },
  { id: "diesel", nome: "Diesel" },
];

const RegisterCoupon: React.FC = () => {
  const alert = useAlert();
  const { store, setStore } = useAuth();

  const refreshUser = async () => {
    const result = await api.get(`/usuario`, {
      headers: { "x-access-token": store.user?.token },
    });
    if (result?.data) {
      setStore({
        ...store,
        user: { ...result.data, token: store?.user?.token },
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      cupomFiscal: "",
      dataCompra: "",
      tipoProduto: "",
      valorPago: "",
      codBar: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (store.user?.token) {
        const result = await api.post("/purchase", values, {
          headers: { "x-access-token": store.user?.token },
        });
        if (result.status === 200) {
          refreshUser();
          alert.success(
            result?.data?.message ||
              "Aguarde o processamento, em breve seus cupons estarão disponíveis",
            {
              onClose: () => {
                resetForm();
              },
            }
          );
        }
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={2}
        sx={{
          px: { xs: 6, md: 10, lg: 20 },
          py: { xs: 6, md: 10, lg: 10 },
          backgroundColor: "white",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h5">
            Cadastrar Cupom Fiscal de Abastecimento
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            InputProps={{
              inputComponent: TextMaskInteger,
              inputProps: {
                component: TextFieldMask,
              },
            }}
            variant="filled"
            fullWidth
            id="cupomFiscal"
            name="cupomFiscal"
            label="Extrato Nº"
            type="tel"
            value={formik.values.cupomFiscal}
            onChange={formik.handleChange}
            error={
              formik.touched.cupomFiscal && Boolean(formik.errors.cupomFiscal)
            }
            helperText={formik.touched.cupomFiscal && formik.errors.cupomFiscal}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              inputComponent: TextMaskDateTime,
              inputProps: {
                component: TextFieldMask,
              },
            }}
            fullWidth
            variant="filled"
            id="dataCompra"
            name="dataCompra"
            label="Data/Hora do Cupom"
            type="dataCompra"
            value={formik.values.dataCompra}
            onChange={formik.handleChange}
            error={
              formik.touched.dataCompra && Boolean(formik.errors.dataCompra)
            }
            helperText={formik.touched.dataCompra && formik.errors.dataCompra}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel
            htmlFor="tipoProduto"
            error={
              formik.touched.tipoProduto && Boolean(formik.errors.tipoProduto)
            }
          >
            Selecione o Combustível
          </InputLabel>
          <Select
            autoFocus
            variant="filled"
            value={formik.values.tipoProduto}
            onChange={formik.handleChange}
            fullWidth
            inputProps={{
              name: "tipoProduto",
              id: "tipoProduto",
            }}
            error={
              formik.touched.tipoProduto && Boolean(formik.errors.tipoProduto)
            }
          >
            {products &&
              products.map((product) => (
                <MenuItem key={product.id} value={product.id}>
                  {product.nome}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText sx={{ color: "red" }}>
            {formik.touched.tipoProduto && formik.errors.tipoProduto}
          </FormHelperText>
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            InputProps={{
              inputComponent: TextMaskLiters,
              inputProps: {
                component: TextFieldMask,
              },
            }}
            variant="filled"
            fullWidth
            id="qtde"
            name="qtde"
            label="Qtde Litros deste combustível"
            type="text"
            value={formik.values.qtde}
            onChange={formik.handleChange}
            error={formik.touched.qtde && Boolean(formik.errors.qtde)}
            helperText={formik.touched.qtde && formik.errors.qtde}
          />
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            InputProps={{
              inputComponent: TextMaskCurrency,
              inputProps: {
                component: TextFieldMask,
              },
            }}
            variant="filled"
            fullWidth
            id="valorPago"
            name="valorPago"
            label="Valor Total Pago"
            type="text"
            value={formik.values.valorPago}
            onChange={formik.handleChange}
            error={formik.touched.valorPago && Boolean(formik.errors.valorPago)}
            helperText={formik.touched.valorPago && formik.errors.valorPago}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputProps={{
              inputComponent: TextMaskInteger,
              inputProps: {
                component: TextFieldMask,
              },
            }}
            variant="filled"
            fullWidth
            id="codBar"
            name="codBar"
            label="Últimos 6 dígitos do código de barras"
            type="tel"
            value={formik.values.codBar}
            onChange={formik.handleChange}
            error={formik.touched.codBar && Boolean(formik.errors.codBar)}
            helperText={formik.touched.codBar && formik.errors.codBar}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={formik.isSubmitting}
          >
            Validar Cupom Fiscal
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RegisterCoupon;
