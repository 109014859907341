import { useEffect, useMemo } from "react";

import Home from "../pages/Home";
import Login from "../pages/Login";
import { Route, Switch } from "react-router";
import ProtectedRoute, { ProtectedRouteProps } from "./ProtectedRoute";
import { useAuth } from "../contexts/useAuth";
import { ProtectedLayout, DefaultLayout } from "../layout";
import Loading from "../components/Loading";
import Sign from "../pages/Sign";
import MyCoupons from "../pages/MyCoupons";
import Forgot from "../pages/Forgot";
import Reset from "../pages/Reset";
import Results from "../pages/Results";
import RegisterCouponTax from "../pages/RegisterCouponTax";
import Draw from "../pages/Draw";
import Show from "../pages/Draw/Show";
import { useLocation, useHistory } from "react-router-dom";
import DrawPromotion from "../pages/Draw/DrawPromotion";
import { responseInterceptor } from "../services/api";
import { useAlert } from "react-alert";

export default function Routes() {
  const { store, loading, signOut, handleOpenLogin } = useAuth();
  const route = useLocation().pathname;
  const history = useHistory();
  const alert = useAlert();
  const defaultProtectedRouteProps: ProtectedRouteProps = useMemo(
    () => ({
      isAuthenticated: !!store?.isAuthenticated,
      authenticationPath: "/login",
      redirectPath: store?.redirectPath,
      loading,
    }),
    [store, loading]
  );

  useEffect(() => {
    const regex = /^(\/forgot|\/sign)$/;
    if (regex.test(route) && store?.isAuthenticated) {
      history.push("/");
    }
  }, [history, route, store?.isAuthenticated]);

  const getLayoutForRoute = (route: string) => {
    const regex = /^\/admin\/\d+\/show$/;

    if (regex.test(route)) {
      return DefaultLayout;
    } else {
      return ProtectedLayout;
    }
  };

  const Layout = getLayoutForRoute(route);

  responseInterceptor(signOut, handleOpenLogin, alert);

  return (
    <>
      <Layout>
        <Switch>
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            path="/register-coupon"
            component={RegisterCouponTax}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            path="/coupons"
            component={MyCoupons}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            path="/admin/draw"
            component={Draw}
          />

          <Route path="/admin/:promotionId/show" component={Show} />
          <Route
            path="/admin/:promotionId/draw-promotion"
            component={DrawPromotion}
          />
          <Route path="/results" component={Results} />
          <Route path="/login" component={Login} />
          <Route path="/sign" component={Sign} />
          <Route path="/forgot" component={Forgot} />
          <Route path="/auth/reset" component={Reset} />
          <Route exact={true} path="/" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="*" component={Home} />
        </Switch>
      </Layout>
      <Loading />
    </>
  );
}
