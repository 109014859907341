import React from "react";
import { DrawingType } from "../../../../model/drawing";
import { CardContent, Grid, Typography } from "@mui/material";

type DrawDashProps = {
  drawing: DrawingType;
};
const DrawDash: React.FC<DrawDashProps> = ({ drawing }) => {
  const texto = drawing.winners?.length
    ? "Total de Ganhadores"
    : "Cupons concorrendo";
  return (
    <Grid
      container
      alignItems={"end"}
      direction={"row"}
      spacing={1}
      paddingRight={2}
    >
      <Grid item xs={4} textAlign={"center"}>
        <CardContent
          sx={{
            border: "solid 1px #74ddb7",
            bgcolor: "#d9fcef",
            borderRadius: 2,
            height: "150px",
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontWeight: "400" }}
            component="div"
            color="#03472e"
          >
            {drawing?.couponsTotal}
          </Typography>
          <Typography variant="body2" color="#03472e">
            {texto}
          </Typography>
        </CardContent>
      </Grid>

      <Grid item xs={4} textAlign={"center"}>
        <CardContent
          sx={{
            border: "solid 1px #e84c4c",
            bgcolor: "#f2c4c4",
            borderRadius: 2,
            height: "150px",
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontWeight: "400" }}
            component="div"
            color="#d01717"
          >
            {drawing?.mostCorrectDozens}
          </Typography>
          <Typography variant="body2" color="#d01717">
            Quantidade de Dezenas acertadas
          </Typography>
        </CardContent>
      </Grid>

      <Grid item xs={4} textAlign={"center"}>
        <CardContent
          sx={{
            border: "solid 1px #4c80e8",
            bgcolor: "#c4def2",
            borderRadius: 2,
            height: "150px",
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontWeight: "400" }}
            component="div"
            color="#1755d0"
          >
            {drawing?.lastBall}
          </Typography>
          <Typography variant="body2" color="#1755d0">
            Última Dezena Sorteada
          </Typography>
        </CardContent>
      </Grid>
    </Grid>
  );
};

export default DrawDash;
