import { createGlobalStyle } from "styled-components";
import "../../node_modules/animate.css/animate.min.css";
export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust:none;
    -ms-text-size-adjust:none;
    text-size-adjust:none;
    font-size-adjust: none;
    max-zoom: 1;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    min-height: 100%;
    height: 100%;
    -webkit-text-size-adjust: none;
    max-zoom: 1;
  }

  body {
    background-color: #e4e4e4;
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    color: #222;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  button {
    cursor: pointer;
  }

  #__react-alert__ > div  {
    z-index: 1500 !important;
  }
  #__react-alert__ > div > div > div {
    width: 100% !important;
    text-transform: none !important;
  }

  .MuiContainer-root {
    flex-grow: 1;
    max-width: 100vw;
    margin: 0;
    padding: 0;
  }

  .legend {
    font-size: 3vh !important;
  }

  .css-0 {
      clear: both;
  }

  @-webkit-keyframes show-big-ball {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0) rotate(0deg);
      transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0) rotate(0deg);
      -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0) rotate(720deg);
      transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0) rotate(720deg);
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
  }
  @keyframes show-big-ball {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0) rotate(0deg);
      transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0) rotate(0deg);
      -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0) rotate(720deg);
      transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0) rotate(720deg);
      -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
  }
  .show-big-ball {
    -webkit-animation-name: show-big-ball;
    animation-name: show-big-ball;
  }

  .animate__animated.animate__delay-6s {
    -webkit-animation-delay:6s;
    animation-delay:6s;
    -webkit-animation-delay:calc(var(--animate-delay)*6);
    animation-delay:calc(var(--animate-delay)*6)
  }
  .animate__animated.animate__delay-7s {
    -webkit-animation-delay:7s;
    animation-delay:7s;
    -webkit-animation-delay:calc(var(--animate-delay)*7);
    animation-delay:calc(var(--animate-delay)*7)
  }
  .animate__animated.animate__delay-8s {
    -webkit-animation-delay:8s;
    animation-delay:8s;
    -webkit-animation-delay:calc(var(--animate-delay)*8);
    animation-delay:calc(var(--animate-delay)*8)
  }
  .animate__animated.animate__delay-9s {
    -webkit-animation-delay:9s;
    animation-delay:9s;
    -webkit-animation-delay:calc(var(--animate-delay)*9);
    animation-delay:calc(var(--animate-delay)*9)
  }
  .animate__animated.animate__delay-10s {
    -webkit-animation-delay:10s;
    animation-delay:10s;
    -webkit-animation-delay:calc(var(--animate-delay)*10);
    animation-delay:calc(var(--animate-delay)*10)
  }

`;
