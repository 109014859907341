import { capitalize } from "@mui/material";
import { format, parse } from "date-fns";
import { ptBR } from "date-fns/locale";
import md5 from "md5";

export { md5 };

export const formatDateTime = (date: string, onlyDate?: boolean) => {
  return capitalize(
    format(
      parse(date, "yyyy-MM-dd HH:mm:ss", new Date()),
      `${onlyDate ? "" : "EEEE, "}dd/MM/yyyy HH:mm`,
      {
        locale: ptBR,
      }
    )
  );
};

export const formatCupomNumber = (number: number) => {
  const newNumber = number.toLocaleString("pt-BR", {
    minimumIntegerDigits: 6,
    useGrouping: true,
  });
  return newNumber.toString().padStart(6, "0");
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};
