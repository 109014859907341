import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

interface FilterProps {
  onChange: (filter: string) => void;
  filter: string;
}

const Filter: React.FC<FilterProps> = ({ onChange, filter }) => {
  return (
    <ButtonGroup
      variant="contained"
      aria-label="outlined secondary button group"
      color="inherit"
    >
      <Button
        color={filter === "concorrendo" ? "secondary" : "inherit"}
        onClick={() => onChange("concorrendo")}
      >
        Concorrendo
      </Button>
      <Button
        color={filter === "antigos" ? "secondary" : "inherit"}
        onClick={() => onChange("antigos")}
      >
        Antigos
      </Button>
      <Button
        color={filter === "contemplados" ? "secondary" : "inherit"}
        onClick={() => onChange("contemplados")}
      >
        Contemplados
      </Button>
    </ButtonGroup>
  );
};

export default Filter;
