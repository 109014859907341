import { DrawingType } from "../model/drawing";
import { LocalStorageService } from "./localStorageService";

export class DrawService {
  static updateDrawing(drawing: DrawingType) {
    try {
      LocalStorageService.setItem(`draw:${drawing.promotion.id}`, drawing);
    } catch (error) {
      console.error("Error update Drawing:", error);
    }
  }
  static getDrawing(promotionId: number) {
    try {
      return LocalStorageService.getItem(`draw:${promotionId}`);
    } catch (error) {
      console.error("Error get Drawing:", error);
    }
  }
}
