import React, { useCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { BigBall, DozenPanel, SmallBall, SphereText } from "./style";
import { DrawingType } from "../../../model/drawing";
import { DrawService } from "../../../services/drawService";
import { useParams } from "react-router-dom";
import Ganhador from "./Ganhador";

let intervalId: NodeJS.Timeout;
type Params = {
  promotionId: string;
};

const Show = () => {
  const { promotionId } = useParams<Params>();
  const [drawing, setDrawing] = useState<DrawingType>();
  const [lastBall, setLastBall] = useState<string>();

  useEffect(() => {
    if (intervalId) clearInterval(intervalId);
    if (promotionId) {
      intervalId = setInterval(() => {
        setDrawing(DrawService.getDrawing(parseInt(promotionId, 10)));
      }, 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [promotionId]);

  const showNextBall = useCallback(() => {
    if (
      drawing?.balls.length &&
      lastBall !== drawing.balls[drawing.balls.length - 1]
    ) {
      setTimeout(() => {
        setLastBall(drawing.balls[drawing.balls.length - 1]);
      }, 1000);
      return true;
    }
  }, [drawing?.balls, lastBall]);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "1920px",
          height: "1080px",
          zIndex: -1,
        }}
        style={{ backgroundColor: "black" }}
      >
        <video autoPlay muted loop width={"100%"} height={1080}>
          <source src="/bg-show-video.mp4" type="video/mp4" />
          Desculpe, seu navegador não suporta vídeos embutidos.
        </video>

        {/* logo */}
        <Box
          sx={{
            position: "fixed",
            top: 80,
            left: 60,
            zIndex: 2,
          }}
        >
          <img src="/images/logo/logo-black.png" width={378} alt="logo" />
        </Box>

        {/* premio */}
        <Box
          sx={{
            position: "fixed",
            top: 330,
            left: 30,
            zIndex: 2,
          }}
        >
          <img
            className="animate__animated animate__pulse animate__infinite animate__slower"
            src="/images/show/moto.png"
            width={540}
            alt="moto"
          />
        </Box>

        {drawing?.balls?.length && (
          <BigBall
            className={showNextBall() && "animate__animated show-big-ball"}
          >
            <SphereText>{drawing?.balls[drawing?.balls.length - 1]}</SphereText>
          </BigBall>
        )}

        {/* total de cupons  */}
        <Box
          sx={{
            position: "fixed",
            top: 360,
            left: 1410,
            zIndex: 2,
            width: 400,
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: 120,
              fontWeight: "bold",
            }}
            color="black"
          >
            {drawing?.couponsTotal}
          </Typography>
        </Box>

        {/* Fireworks  */}
        {drawing?.winners?.length && (
          <Box
            sx={{
              position: "fixed",
              top: 360,
              left: 0,
              zIndex: 3,
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              hidden={drawing.showWinners}
              sx={{
                fontSize: 150,
                fontWeight: "bold",
                textShadow: "5px 5px 12px rgba(0, 0, 0, 0.7)",
              }}
              color="red"
              className="animate__animated animate__tada animate__infinite "
            >
              PARABÉNS SAIU {drawing?.winners?.length}{" "}
              {drawing.winners.length > 1 ? "GANHADORES" : "GANHADOR"}
            </Typography>
          </Box>
        )}

        <DozenPanel>
          {drawing?.balls.map((value, index) => (
            <SmallBall
              key={value}
              className="animate__animated animate__rotateIn animate__delay-1s"
            >
              <SphereText fontSize="38px">{value}</SphereText>
            </SmallBall>
          ))}
        </DozenPanel>
      </Box>
      <Box
        sx={{
          width: "80%",
          position: "fixed",
          top: 200,
          left: "10%",
        }}
      >
        {drawing?.winners && drawing?.showWinners && (
          <Ganhador drawing={drawing} />
        )}
      </Box>
    </>
  );
};

export default Show;
